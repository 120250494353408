import script from "./ResetPasswordSent.vue?vue&type=script&lang=ts&setup=true"
export * from "./ResetPasswordSent.vue?vue&type=script&lang=ts&setup=true"

import "./ResetPasswordSent.vue?vue&type=style&index=0&id=6b0edb2a&lang=scss"
/* custom blocks */
import block0 from "./locale/ResetPasswordSent.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=C%3A%5CJenkins%5Cworkspace%5CPlatformIdentity_develop%5Cfrontend%5Clogin%5Csrc%5Cviews%5CResetPasswordSent.vue&lang=yaml&external"
if (typeof block0 === 'function') block0(script)


const __exports__ = script;

export default __exports__