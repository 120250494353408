import script from "./ResetPassword.vue?vue&type=script&lang=ts&setup=true"
export * from "./ResetPassword.vue?vue&type=script&lang=ts&setup=true"

import "./ResetPassword.vue?vue&type=style&index=0&id=e0e68618&lang=scss"
/* custom blocks */
import block0 from "./locale/ResetPassword.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=C%3A%5CJenkins%5Cworkspace%5CPlatformIdentity_develop%5Cfrontend%5Clogin%5Csrc%5Cviews%5CResetPassword.vue&lang=yaml&external"
if (typeof block0 === 'function') block0(script)


const __exports__ = script;

export default __exports__